import React, { useState } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/core";
import axios from "axios";

import Layout from "../components/layout";

/*
const questionTopics = [
  "theoretical paleontology",
  "ineffective altruism",
  "corporate teambuilding via PowerPoint jeopardy",
];

const questionTopic =
  questionTopics[Math.floor(Math.random() * questionTopics.length)];
*/
const QuestionsPage = ({ data }) => {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(null);

  const sendMessage = () => {
    const date = new Date().toISOString();
    axios
      .post("https://willkueshner.com/.netlify/functions/questions", {
        date: date,
        question: message,
      })
      .then(function (response) {
        setStatus("Success!");
        setMessage("");
      })
      .catch(function (error) {
        setStatus("Error");
      });
  };

  return (
    <Layout title="questions">
      <section css={{ marginBottom: "40px" }}>
        <p css={{ marginBottom: "20px" }}>
          Questions?
        </p>
        <textarea
          css={css`
            box-sizing: border-box;
            max-width: 100%;
            display: block;
            margin-bottom: 20px;
            font-size: inherit;
            font-family: inherit;
          `}
          rows="6"
          cols="50"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <button
          css={css`
            font-size: inherit;
            font-family: inherit;
            margin-bottom: 10px;
          `}
          onClick={sendMessage}
        >
          Submit question
        </button>
        {status !== null ? <p>{status}</p> : null}
      </section>
      <section>
        {data.questions.nodes.map((question) => (
          <div key={question.id} css={{ marginBottom: "20px" }}>
            <div css={{ marginBottom: "10px" }}>
              <p
                css={css`
                  display: inline;
                  color: #eb00ff;
                  margin-right: 20px;
                `}
              >
                {new Date(question.date).toISOString().slice(0, 10) +
                  " at " +
                  new Date(question.date).toLocaleTimeString()}
              </p>
              <h3
                css={css`
                  display: inline;
                  font-weight: bold;
                `}
              >
                {question.question}
              </h3>
            </div>
            <p css={{ marginLeft: "20px" }}>{question.response}</p>
          </div>
        ))}
      </section>
    </Layout>
  );
};

export const query = graphql`
  query QuestionsPageQuery {
    questions: allSanityQuestion(filter: { response: { ne: null } }) {
      nodes {
        question
        response
        date
        id
      }
    }
  }
`;

export default QuestionsPage;
